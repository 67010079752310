/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import {
  Flex,
  VStack,
  Text,
  Box,
  useMediaQuery,
  Image,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Icon,
  Button,
  Input,
  Textarea,
  useToast,
  Link,
  ModalFooter,
  Progress,
} from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { ImWarning } from "react-icons/im";
import { MdFileUpload } from "react-icons/md";
import { GrClose } from "react-icons/gr";
import { FiTrash2 } from "react-icons/fi";
import { SlPencil } from "react-icons/sl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useDeleteClinicalURLsMutation,
  useUpdateClinicalHistoryMutation,
  useUpdateRadiologyInfoMutation,
  useDeleteRadiologyURLsMutation,
  useGetUserInfoQuery,
} from "../state/API/HospitalApi";

import "../App.css";
import Environment from "../environment";
import { updateCase } from "../state/Reducers/newCaseReducer";
import { isActionValid } from "../hooks/utility";

const ClinicalHistory = ({ caseInfo, permissions }) => {
  const [fileClinicalBoolean, setFileClinicalBoolean] = useState(false);
  const [fileRadiologyBoolean, setFileRadiologyBoolean] = useState(false);
  // localStorage.setItem("tab", "2");
  const [ifSmallScreen] = useMediaQuery("(max-width:1920px)");
  const [is1280] = useMediaQuery("(width: 1280px)");
  const [click, setClick] = useState(false);
  const [click1, setClick1] = useState(false);
  const [isModal1Open, setIsModal1Open] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [isModal3Open, setIsModal3Open] = useState(false);
  const [isModal4Open, setIsModal4Open] = useState(false);
  const [timeStamp, setTimeStamp] = useState("");
  const [timeStamp1, setTimeStamp1] = useState("");
  const { user } = useAuth0();
  const [isEdit, setIsEdit] = useState(false);
  const [textAreaDescription, setTextArea] = useState("");
  const [textAreaDescription1, setTextArea1] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [allFiles1, setAllFiles1] = useState([]);
  const [allFiles2, setAllFiles2] = useState([]);
  const [allFiles3, setAllFiles3] = useState([]);
  const [userName, setUserName] = useState("");
  const [blurState, setBlurState] = useState(false);
  const [radiologyImages] = useUpdateRadiologyInfoMutation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const dispatch = useDispatch();
  const { caseDetails } = useSelector((state) => state.newCaseState);
  const handleCaseDetails = (e) => {
    dispatch(updateCase({ name: e.target.name, value: e.target.value }));
  };
  const { data: userInfo, isLoading } = useGetUserInfoQuery(
    {
      subClaim: user?.sub,
    },
    { skip: !user?.sub }
  );

  useEffect(() => {
    setUserName(user.given_name);
  }, []);
  let count = 0;
  let count1 = 0;

  const onSelectFile = async (e) => {
    const { files } = e.target;
    const filesArray = Array.from(files);
    if (filesArray.length > 0) {
      setFileClinicalBoolean(true);
    }
    setAllFiles(filesArray);
  };
  const onSelectFile1 = async (e) => {
    const { files } = e.target;
    const filesArray = Array.from(files);
    if (filesArray.length > 0) {
      setFileRadiologyBoolean(true);
    }
    setAllFiles1(filesArray);
  };
  const onDeselectFile = async (e, value) => {
    const updatedFiles = allFiles.filter((file) => file.name !== value);
    setAllFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setFileClinicalBoolean(false);
    }
  };
  const onDeselectFile1 = async (e, value) => {
    const updatedFiles = allFiles1.filter((file) => file.name !== value);
    setAllFiles1(updatedFiles);
    if (updatedFiles.length === 0) {
      setFileRadiologyBoolean(false);
    }
  };
  const setModalIdFunction = async (id) => {
    const updatedFiles = caseInfo?.clinicalHistory?.docsUrl
      ?.filter((obj) => obj.isDeleted === false)
      .filter((object) => object._id === id);
    setAllFiles2(updatedFiles);
    const dateObj = new Date(updatedFiles[0].uploadedAt);
    const options = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObj
    );

    const parts = formattedDate.split(" ");
    const time = `${dateObj.getHours()}:${dateObj.getMinutes()}`;
    const day = parts[1].replace(",", "");
    const month = parts[0];

    const formattedDateString = `${time} ${day} ${month} ${dateObj.getFullYear()}`;
    setTimeStamp(formattedDateString);
  };
  const setModalIdFunction1 = async (id) => {
    const updatedFiles = caseInfo?.radiology?.radiologyImageUrl
      ?.filter((obj) => obj.isDeleted === false)
      .filter((object) => object._id === id);
    setAllFiles3(updatedFiles);
    const dateObj = new Date(updatedFiles[0].uploadedAt);
    const options = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObj
    );

    const parts = formattedDate.split(" ");
    const time = `${dateObj.getHours()}:${dateObj.getMinutes()}`;
    const day = parts[1].replace(",", "");
    const month = parts[0];

    const formattedDateString = `${time} ${day} ${month} ${dateObj.getFullYear()}`;
    setTimeStamp1(formattedDateString);
  };

  const [deleteClinicalURLs] = useDeleteClinicalURLsMutation();
  const [deleteRadiologyURLs] = useDeleteRadiologyURLsMutation();
  const [updateClinicalHistory] = useUpdateClinicalHistoryMutation();
  const toast = useToast();
  const deleteImages = async (url) => {
    try {
      await deleteClinicalURLs({
        caseId: caseInfo._id,
        urls: url,
      }).unwrap();

      toast({
        title: "Status",
        description: "Document removed",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Registration failed, Try again: ", err);
      toast({
        title: "Error",
        description: "Could not delete image. Try again later.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };
  const handelDeleteFile = async (id) => {
    try {
      await deleteClinicalURLs({
        caseId: caseInfo._id,
        docId: id,
      }).unwrap();

      toast({
        title: "Status",
        description: "Document removed",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Registration failed, Try again: ", err);
      toast({
        title: "Error",
        description: "Could not delete image. Try again later.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    setIsModal3Open(false);
  };
  const handelDeleteFile1 = async (id) => {
    try {
      await deleteRadiologyURLs({
        caseId: caseInfo._id,
        docId: id,
        subClaim: user?.sub,
      }).unwrap();
      toast({
        title: "Status",
        description: "Document removed",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (err) {
      console.error("Registration failed, Try again: ", err);
      toast({
        title: "Error",
        description: "Could not delete image. Try again later.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    setIsModal4Open(false);
  };
  const handleUpdateClinicalHistory = async () => {
    // const imagesArray = allFiles.map((file) => file);
    // console.log("caseDetails", caseDetails);
    // console.log("caseDetails", clinicalHistoryForm);
    // console.log("obj", imagesArray);
    setClick(true);
    const form = new FormData();
    allFiles.map((file) => {
      form.append("files", file);
    });

    // dispatch(addClinicalHistory(imagesArray));
    try {
      setBlurState(true);
      const clinicalHistoryUrls = await axios.post(
        `${Environment.USER_URL}/multi_clinical_history`,
        form,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );
      const dataarray = [];
      clinicalHistoryUrls.data.urls.forEach((element, i) => {
        const obj = {
          docUrl: element,
          docDescription: textAreaDescription,
          uploadedBy: `${userInfo.firstName} ${userInfo.lastName}`,
          docName: allFiles[i]?.name,
          docType:
            allFiles[i]?.name.slice(-3) === "peg"
              ? "jpeg"
              : allFiles[i]?.name.slice(-3),
          docSize: allFiles[i]?.size,
        };
        if (obj.docName !== undefined) {
          dataarray.push(obj);
        }
      });
      await updateClinicalHistory({
        caseId: caseInfo._id,
        docs: dataarray,
      });
      toast({
        title: "Success",
        description: `Clinical history updated`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch {
      toast({
        title: "Error",
        description: `Some error occurred.`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
    setAllFiles([]);
    setUploadProgress(0);
    setIsEdit(false);
    setIsModal1Open(false);
    setTextArea("");
    setBlurState(false);
    setClick(false);
  };

  /* Radiology handle function */
  const getToken = () => {
    const { body } = JSON.parse(localStorage.getItem(Environment.AUTH0_TOKEN));

    const { access_token: accessToken } = body;
    return `Bearer ${accessToken}`;
  };
  const handleUpdateRadiologyHistory = async () => {
    // const imagesArray = allFiles1.map((file) => file);
    // dispatch(addRadiologyImages(imagesArray));

    setClick1(true);
    const form = new FormData();
    allFiles1.map((file) => {
      form.append("files", file);
    });

    try {
      setBlurState(true);
      const headers = {
        Authorization: getToken(),
      };
      const config = {
        onUploadProgress: (curProgress) => {
          const percentCompleted = Math.round(
            (curProgress.loaded * 100) / curProgress.total
          );

          setUploadProgress(percentCompleted);
        },
        headers,
      };
      const radioUrls = await axios.post(
        `${Environment.USER_URL}/multi_media_file_upload`,
        form,
        config
        // {
        //   onUploadProgress: (progressEvent) => {
        //     const progress = Math.round(
        //       (progressEvent.loaded / progressEvent.total) * 100
        //     );
        //     setUploadProgress(progress);
        //   },
        // }
      );
      const dataarray1 = [];
      radioUrls.data.urls.forEach((element, i) => {
        const obj = {
          docUrl: element,
          docDescription: textAreaDescription1,
          uploadedBy: `${userInfo.firstName} ${userInfo.lastName}`,
          docName: allFiles1[i]?.name,
          docType:
            allFiles1[i]?.name.slice(-3) === "peg"
              ? "jpeg"
              : allFiles1[i]?.name.slice(-3),
          docSize: allFiles1[i]?.size,
        };
        if (obj.docName !== undefined) {
          dataarray1.push(obj);
        }
      });

      await radiologyImages({
        caseId: caseInfo._id,
        docs: dataarray1,
        subClaim: user?.sub,
      });
      // await radiologyImages({
      //   caseId: caseInfo._id,
      //   description: caseDetails.radiologyInstructions,
      //   urls: radioUrls.data.urls,
      // });
      toast({
        title: "Success",
        description: `Radiology history updated`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch {
      toast({
        title: "Error",
        description: `Some error occurred.`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }

    setAllFiles1([]);
    setUploadProgress(0);
    setIsEdit(false);
    setIsModal2Open(false);
    setTextArea1("");
    setBlurState(false);
    setClick1(false);
  };
  /* Radiology handle function end */

  return (
    <Box>
      <Box
        // mt="0.2vh"
        w="100%"
        h="100%"
        minH="72vh"
        py="1vw"
        fontSize={ifSmallScreen ? "14px" : " 0.72916vw"}
        bgColor="light.500"
        // w="100%"
        display="flex"
        justifyContent="space-between"
        overflow="auto"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#C4C4C4",
          },
        }}
        // background="light.1004"
      >
        <Flex
          w="50%"
          maxW="50%"
          background="light.100"
          p="0.937vw"
          dropShadow="1px 1px 2px rgba(176, 200, 214, 0.1)"
          mr="0.8333vw"
          ml="0.8333vw"
          borderRadius="10px"
        >
          <VStack w="100%" h="100%" alignItems="flex-start">
            <HStack justifyContent="space-between" w="100%">
              <Text
                h="5vh"
                w="60%"
                fontSize={ifSmallScreen ? "14px" : " 0.729vw"}
                fontWeight={600}
                alignItems="center"
                py="1.1vh"
                pl="0.88vw"
                color="#1B75BC"
              >
                Clinical History
              </Text>

              <Button
                bgColor="#FFFFFF"
                _focus={{ outline: "none" }}
                onClick={() => setIsModal1Open(true)}
                border="1px solid #E8E8EB"
                borderRadius="5px"
                fontWeight={500}
                fontSize={ifSmallScreen ? "13px" : "0.713vw"}
              >
                <HStack px="15px" py="11px">
                  <Box pr="10px">
                    <AiOutlineCloudUpload size={22} />
                  </Box>
                  <Text>Upload Document</Text>
                </HStack>
              </Button>
            </HStack>
            {isEdit ? (
              <Flex
                alignItems="flex-start"
                w="100%"
                fontSize={ifSmallScreen ? "14px" : "0.729vw"}
                direction="column"
              >
                <HStack
                  maxW="100%"
                  overflowX="scroll"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#C4C4C4",
                    },
                  }}
                >
                  {caseInfo?.clinicalHistory?.docsUrl
                    ?.filter((obj) => obj.isDeleted === false)
                    ?.map((object, index) => {
                      return object.docUrl ? (
                        <Flex key={object._id} direction="column">
                          {object?.docUrl.slice(-3) === "png" ||
                          object?.docUrl.slice(-3) === "jpg" ||
                          object?.docUrl.slice(-4) === "jpeg" ? (
                            <Image
                              src={object.docUrl}
                              alt=""
                              minW="12vw"
                              maxW="15vw"
                              css={{ cursor: "-webkit-grab" }}
                            />
                          ) : object?.docUrl.slice(-3) === "pdf" ? (
                            <Flex direction="column">
                              <object
                                data={object.docUrl}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                style={{
                                  overflow: "hidden",
                                  cursor: "-webkit-grab",
                                }}
                              >
                                <p>
                                  Alternative text - include a link{" "}
                                  <a href={object.docUrl}>to the PDF!</a>
                                </p>
                              </object>
                            </Flex>
                          ) : (
                            <iframe
                              title={object.docUrl}
                              src={`https://view.officeapps.live.com/op/embed.aspx?src=${object.docUrl}&embedded=true`}
                              frameBorder="0"
                            />
                          )}

                          <Flex
                            justifyContent="space-between"
                            w="100%"
                            alignItems="center"
                          >
                            <Text>Image name: Report Page {index + 1}</Text>
                            <Button
                              borderRadius="0px"
                              size="xs"
                              value={object}
                              _focus={{ outline: "none" }}
                              bgColor="#fff"
                              onClick={() => deleteImages(object.docUrl)}
                            >
                              <GrClose />
                            </Button>
                          </Flex>
                        </Flex>
                      ) : null;
                    })}
                </HStack>

                <Flex
                  border="1px dashed #3B5D7C"
                  borderRadius="0px"
                  bgColor="light.1000"
                  position="relative"
                  w="100%"
                  h="6vh"
                  direction="row"
                  mt="2vh"
                >
                  <Flex alignItems="center" pl="35%">
                    UPLOAD REQUISITION FORM{" "}
                    <MdFileUpload size={20} style={{ marginLeft: "0.5vw" }} />
                  </Flex>

                  <Input
                    id="clinical_history"
                    name="clinicalHistory"
                    position="absolute"
                    opacity="0"
                    height="100%"
                    onInput={(e) => onSelectFile(e)}
                    top="0"
                    left="0"
                    bg="light.100"
                    borderRadius={0}
                    borderColor="#000"
                    resize="none"
                    type="file"
                    accept=".pdf"
                    multiple
                  />
                </Flex>
                {caseDetails?.clinicalHistory.length > 0
                  ? caseDetails?.clinicalHistory.map((image) => {
                      return (
                        <Flex key={image.name}>Uploading: {image.name}</Flex>
                      );
                    })
                  : null}
                <VStack alignItems="flex-start" w="100%" mt="1.5vh">
                  <Text>Description:</Text>
                  <Textarea
                    h="100%"
                    maxH="15.0925vh"
                    minH="15.0925vh"
                    id="clinical_history_details"
                    name="clinicalHistoryDetails"
                    bg="light.100"
                    borderRadius={0}
                    resize="none"
                    // color="light.1002"
                    border="none"
                    placeholder="Enter text here"
                    value={caseDetails.clinicalHistoryDetails}
                    onChange={(e) => handleCaseDetails(e)}
                  />
                </VStack>
                <Flex w="100%" mt="1vh" gap="1vw" justifyContent="flex-end">
                  <Button
                    borderRadius={0}
                    h="4vh"
                    bgColor="light.1000"
                    fontWeight={200}
                    _focus={{ outline: "none" }}
                    onClick={() => setIsEdit(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    borderRadius={0}
                    bgColor="light.700"
                    h="4vh"
                    color="#fff"
                    fontWeight={200}
                    _focus={{ outline: "none" }}
                    _hover={{ background: "light.700" }}
                    onClick={handleUpdateClinicalHistory}
                  >
                    Save Actions
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <VStack
                pt="1.95vh"
                alignItems="flex-start"
                maxW="100%"
                fontSize={ifSmallScreen ? "14px" : "0.729vw"}
              >
                <Flex
                  maxW="100%"
                  flexWrap="wrap"
                  gap={is1280 ? "25px" : "15px"}
                  pl="0.88vw"
                  pr="0.90vw"
                >
                  {caseInfo?.clinicalHistory?.docsUrl
                    ?.filter((obj) => obj.isDeleted === false)
                    .map((object) => {
                      count += 1;
                      // console.log("caseInfo", caseInfo);
                      return object.docUrl ? (
                        <Flex
                          key={count}
                          border="1px solid #E8E8EB"
                          borderRadius="10px"
                          minW="270px"
                          maxW="270px"
                          minH="500px"
                          maxH="500px"
                          onClick={() => {
                            setModalIdFunction(object._id);
                            setIsModal3Open(true);
                          }}
                          overflow="hidden"
                          pb="2px"
                          css={{ cursor: "-webkit-grab" }}
                        >
                          {object?.docUrl.slice(-3) === "png" ||
                          object?.docUrl.slice(-3) === "jpg" ||
                          object?.docUrl.slice(-4) === "jpeg" ? (
                            <Flex
                              pt="17px"
                              pb="17px"
                              pl="20px"
                              pr="20px"
                              direction="column"
                            >
                              {/* <Box>
                                <Image
                                  src={object.docUrl}
                                  alt=""
                                  width="270px"
                                  height="250px"
                                  css={{ cursor: "-webkit-grab" }}
                                />
                              </Box> */}
                              <Box width="230px" height="280px">
                                <Image
                                  src={object?.docUrl}
                                  alt="Image description"
                                  width="100%"
                                  height="100%"
                                  objectFit="contain"
                                />
                              </Box>
                              {/* <Link href={object.docUrl} isExternal> */}
                              <Flex
                                alignItems="center"
                                fontSize={ifSmallScreen ? "14px" : "0.729vw"}
                                fontWeight={600}
                                pt="12px"
                              >
                                <Box w="100%" wordBreak="break-word">
                                  <Text color="#000000">{object?.docName}</Text>
                                  {/* <HiExternalLink
                                style={{
                                  color: "#3B5D7C",
                                }}
                              /> */}
                                </Box>
                              </Flex>

                              {/* </Link> */}
                              <Flex direction="column">
                                <Flex
                                  fontSize="13px"
                                  color="#6A6A6A"
                                  fontWeight={500}
                                  pt="16px"
                                  pb="10px"
                                >
                                  <Text>Description</Text>
                                </Flex>
                                <Flex
                                  fontSize="13px"
                                  color="#000000"
                                  fontWeight={500}
                                >
                                  {/* <Box
                                    w="100%"
                                    wordBreak="break-word"
                                    overflow="hidden"
                                    h="90%"
                                  >
                                    <Text>
                                      {object?.docDescription
                                        ? object?.docDescription
                                        : " No Description"}
                                    </Text>
                                  </Box> */}
                                  <Text
                                    w="100%"
                                    wordBreak="break-word"
                                    // overflow="hidden"
                                    textOverflow="ellipsis"
                                    css={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      boxOrient: "vertical",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 4,
                                    }}
                                  >
                                    {object?.docDescription
                                      ? object?.docDescription
                                      : " No Description"}
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          ) : object?.docUrl.slice(-3) === "pdf" ? (
                            <Flex
                              direction="column"
                              pt="17px"
                              pb="17px"
                              pl="20px"
                              pr="20px"
                            >
                              <Box>
                                <object
                                  data={object?.docUrl}
                                  type="application/pdf"
                                  width="230px"
                                  height="280px"
                                  overflow="hidden"
                                  style={{
                                    overflowY: "hidden",
                                    cursor: "-webkit-grab",
                                  }}
                                >
                                  <p>
                                    Alternative text - include a link{" "}
                                    <a href={object?.docUrl}>to the PDF!</a>
                                  </p>
                                </object>
                              </Box>
                              {/* <Link href={object?.docUrl} isExternal> */}
                              <Flex
                                alignItems="center"
                                fontSize={ifSmallScreen ? "14px" : "0.729vw"}
                                fontWeight={600}
                                pt="12px"
                              >
                                <Box w="100%" wordBreak="break-word">
                                  <Text color="#000000">{object?.docName}</Text>
                                </Box>
                                {/* <HiExternalLink
                                style={{
                                  color: "#3B5D7C",
                                }}
                              /> */}
                              </Flex>
                              {/* </Link> */}
                              <Flex direction="column">
                                <Flex
                                  fontSize="13px"
                                  color="#6A6A6A"
                                  fontWeight={500}
                                  pt="16px"
                                  pb="16px"
                                >
                                  <Text>Description</Text>
                                </Flex>
                                <Flex
                                  fontSize="13px"
                                  color="#000000"
                                  fontWeight={500}
                                >
                                  <Text
                                    w="100%"
                                    wordBreak="break-word"
                                    // overflow="hidden"
                                    textOverflow="ellipsis"
                                    css={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      boxOrient: "vertical",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 4,
                                    }}
                                  >
                                    {object?.docDescription
                                      ? object?.docDescription
                                      : " No Description"}
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          ) : (
                            <Flex
                              direction="column"
                              pt="17px"
                              pb="17px"
                              pl="20px"
                              pr="20px"
                            >
                              <iframe
                                title={object?.docUrl}
                                // src={`https://docsUrl.google.com/gview?url=${object.docurl}&embedded=true`}
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${object.docUrl}&embedded=true`}
                                frameBorder="0"
                                width="230px"
                                height="280px"
                                style={{
                                  overflow: "hidden",
                                }}
                              />
                              {/* <Link href={object?.docUrl} isExternal> */}
                              <Flex
                                alignItems="center"
                                fontSize={ifSmallScreen ? "14px" : "0.729vw"}
                                fontWeight={600}
                                pt="12px"
                              >
                                <Text color="#000000">{object?.docName}</Text>
                                {/* <HiExternalLink
                                style={{
                                  color: "#3B5D7C",
                                }}
                              /> */}
                              </Flex>
                              {/* </Link> */}
                              <Flex direction="column">
                                <Flex
                                  fontSize="13px"
                                  color="#6A6A6A"
                                  fontWeight={500}
                                  pt="16px"
                                  pb="16px"
                                >
                                  <Text>Description</Text>
                                </Flex>
                                <Flex
                                  fontSize="13px"
                                  color="#000000"
                                  fontWeight={500}
                                >
                                  <Text
                                    w="100%"
                                    wordBreak="break-word"
                                    // overflow="hidden"
                                    textOverflow="ellipsis"
                                    css={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      boxOrient: "vertical",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 4,
                                    }}
                                  >
                                    {object?.docDescription
                                      ? object?.docDescription
                                      : " No Description"}
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          )}
                          {/* 
                        <Modal
                          isOpen={isOpen}
                          onClose={onClose}
                          scrollBehavior={scrollBehavior}
                        >
                          <ModalOverlay />
                          <ModalContent minW="1000px">
                            <ModalHeader>Clinical History Form</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody
                              css={{
                                "&::-webkit-scrollbar": {
                                  width: "4px",
                                },
                                "&::-webkit-scrollbar-track": {
                                  width: "6px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                  background: "#C4C4C4",
                                },
                              }}
                            >
                              {url?.docUrl.slice(-3) === "png" ||
                              url?.docUrl.slice(-3) === "jpg" ||
                              url?.docUrl.slice(-4) === "jpeg" ? (
                                <Image
                                  src={url.docUrl}
                                  alt=""
                                  minW="12vw"
                                  maxW="15vw"
                                />
                              ) : (
                                // <Text w="50%">{url.docUrl}</Text>
                                <iframe src={url.docUrl} title={url.docUrl} />
                              )}
                            </ModalBody>
                          </ModalContent>
                        </Modal> */}
                        </Flex>
                      ) : null;
                    })}
                </Flex>

                {/* <Text textTransform="capitalize">
                Description:
                {caseInfo?.clinicalHistory?.description
                  ? caseInfo?.clinicalHistory?.description
                  : " No Description"}
              </Text> */}
              </VStack>
            )}
          </VStack>
        </Flex>
        <Flex
          w="50%"
          flexDirection="column"
          background="light.100"
          p="0.937vw"
          mr="0.8333vw"
          borderRadius="10px"
        >
          <HStack justifyContent="space-between" w="100%" pb="10px" pr="1px">
            <Text
              h="5vh"
              w="60%"
              fontSize={ifSmallScreen ? "14px" : " 0.8333vw"}
              fontWeight={600}
              alignItems="center"
              py="1.1vh"
              pl="0.88vw"
              color="#1B75BC"
            >
              Radiology Images
            </Text>
            <Button
              bgColor="#FFFFFF"
              _focus={{ outline: "none" }}
              onClick={() => setIsModal2Open(true)}
              border="1px solid #E8E8EB"
              borderRadius="5px"
              fontWeight={500}
              fontSize={ifSmallScreen ? "13px" : "0.713vw"}
              disabled={isActionValid("radiologyImageAdd", permissions)}
            >
              <HStack px="15px" py="11px">
                <Box pr="10px">
                  <AiOutlineCloudUpload size={22} />
                </Box>
                <Text>Upload new image</Text>
              </HStack>
            </Button>
          </HStack>

          <VStack
            pt="1.85vh"
            alignItems="flex-start"
            maxW="100%"
            fontSize={ifSmallScreen ? "14px" : "0.729vw"}
          >
            {/* <Text
            h="5vh"
            w="100%"
            fontSize={ifSmallScreen ? "14px" : " 0.8333vw"}
            fontWeight={400}
            background="light.1000"
            alignItems="center"
            py="1.1vh"
            pl="0.88vw"
          >
            Radiology Images
          </Text> */}
            {!isActionValid("radiologyImageView", permissions) ? (
              <Flex
                maxW="100%"
                flexWrap="wrap"
                // justifyContent="space-between"
                gap={is1280 ? "25px" : "15px"}
                pl="0.88vw"
                pr="0.90vw"
              >
                {caseInfo?.radiology?.radiologyImageUrl
                  ?.filter((obj) => obj.isDeleted === false)
                  .map((object) => {
                    count1 += 1;
                    return caseInfo?.radiology?.radiologyImageUrl?.filter(
                      (obj) => obj.isDeleted === false
                    ).length > 0 ? (
                      <Box
                        key={count1}
                        border="1px solid #E8E8EB"
                        borderRadius="10px"
                        minW="270px"
                        maxW="270px"
                        minH="500px"
                        maxH="500px"
                        onClick={() => {
                          setModalIdFunction1(object._id);
                          setIsModal4Open(true);
                        }}
                        overflow="hidden"
                        css={{ cursor: "-webkit-grab" }}
                      >
                        <Flex p="15px" direction="column">
                          {object?.docUrl.slice(-3) === "png" ||
                          object?.docUrl.slice(-3) === "jpg" ||
                          object?.docUrl.slice(-4) === "jpeg" ? (
                            // <Image
                            //   src={object.docUrl}
                            //   alt=""
                            //   // minW="12vw"
                            //   // maxW="50%"
                            //   maxH="auto"
                            //   css={{ cursor: "-webkit-grab" }}
                            // />
                            <Box width="230px" height="280px">
                              <Image
                                src={object.docUrl}
                                alt="Image description"
                                width="100%"
                                height="100%"
                                objectFit="contain"
                                css={{ cursor: "-webkit-grab" }}
                              />
                            </Box>
                          ) : object?.docUrl.slice(-3) === "pdf" ? (
                            <Flex direction="column">
                              <object
                                data={object.docUrl}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                style={{
                                  overflow: "hidden",
                                  cursor: "-webkit-grab",
                                }}
                              >
                                <p>
                                  Alternative text - include a link{" "}
                                  <a href={object.docUrl}>to the PDF!</a>
                                </p>
                              </object>
                            </Flex>
                          ) : (
                            <iframe
                              title={object.docUrl}
                              src={`https://view.officeapps.live.com/op/embed.aspx?src=${object.docUrl}&embedded=true`}
                              frameBorder="0"
                            />
                          )}

                          <Flex direction="column">
                            <Flex
                              alignItems="center"
                              fontSize={ifSmallScreen ? "14px" : "0.729vw"}
                              fontWeight={600}
                              pt="12px"
                            >
                              <Box w="100%" wordBreak="break-word">
                                <Text color="#000000">{object?.docName}</Text>
                                {/* <HiExternalLink
                               style={{
                                 color: "#3B5D7C",
                               }}
                             /> */}
                              </Box>
                            </Flex>
                            <Flex
                              fontSize="13px"
                              color="#6A6A6A"
                              fontWeight={500}
                              pt="16px"
                              pb="10px"
                            >
                              <Text>Description</Text>
                            </Flex>
                            <Box
                              fontSize="13px"
                              color="#000000"
                              fontWeight={500}
                              h="90px"
                            >
                              {/* <Box w="100%" wordBreak="break-word" h="100px">
                             <Text>
                               {object?.docDescription
                                 ? object?.docDescription
                                 : "No Description"}
                             </Text>
                           </Box> */}
                              <Text
                                w="100%"
                                wordBreak="break-word"
                                // overflow="hidden"
                                textOverflow="ellipsis"
                                css={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  boxOrient: "vertical",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 4,
                                }}
                              >
                                {object?.docDescription
                                  ? object?.docDescription
                                  : " No Description"}
                              </Text>
                            </Box>
                          </Flex>
                        </Flex>
                      </Box>
                    ) : (
                      <VStack
                        pt="1.85vh"
                        alignItems="center"
                        maxW="100%"
                        justifyContent="center"
                        h="100%"
                      >
                        <Icon as={ImWarning} w="2vw" />
                        <Text fontSize={ifSmallScreen ? "14px" : " 0.8333vw"}>
                          No Radiology Images uploaded!
                        </Text>
                      </VStack>
                    );
                  })}
              </Flex>
            ) : (
              <Flex
                w="100%"
                minH="70vh"
                alignItems="center"
                justifyContent="center"
              >
                You don&apos;t have permissions to view radiology images
              </Flex>
            )}
          </VStack>
        </Flex>
      </Box>

      {/* modal start 1 */}
      <Modal
        blockScrollOnMount={false}
        isOpen={isModal1Open}
        onClose={() => setIsModal1Open(false)}
        height={ifSmallScreen ? "500px" : "635px"}
        width={ifSmallScreen ? "500px" : "716px"}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            // fontSize="15.5px"
            fontSize={ifSmallScreen ? "13px" : "15.5px"}
            font-weight="500"
            // pl="27px"
            // pb="15px"
            // pt="19px"
            pl={ifSmallScreen ? "20px" : "27px"}
            pb={ifSmallScreen ? "10px" : "15px"}
            pt={ifSmallScreen ? "10px" : "19px"}
            bg="#F0F2FF"
            borderStyle="solid"
            borderWidth="0px 0px 2px 0px"
            borderColor="#DEDEDE"
          >
            Requisition form
          </ModalHeader>
          <ModalCloseButton
            // pb="15px"
            // pt="21px"
            // mr="10.5"
            pb={ifSmallScreen ? "20px" : "15px"}
            pt={ifSmallScreen ? "10px" : "21px"}
            mr={ifSmallScreen ? "10px" : "10.5"}
            _hover={{ opacity: 1 }}
          />
          <ModalBody bg="#FCFCFC">
            <Flex>
              <HStack
                mt={ifSmallScreen ? "5px" : "20px"}
                ml={ifSmallScreen ? "1px" : "2px"}
                justifyContent="space-between"
              >
                <Flex
                  // width="240px"
                  width={fileClinicalBoolean === false ? "529px" : "240px"}
                  height={ifSmallScreen ? "245px" : "272px"}
                  border="1px dashed #1B75BC"
                  borderRadius="5px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Input
                    id="clinical_history"
                    name="clinicalHistory"
                    position="absolute"
                    opacity="0"
                    onInput={(e) => onSelectFile(e)}
                    type="file"
                    accept=".pdf,.docx, image/*"
                    // width="40%"
                    width={ifSmallScreen ? "200px" : "240px"}
                    height={ifSmallScreen ? "245px" : "272px"}
                    // height="50%"
                    multiple
                  />
                  <Box
                    fontSize="12px"
                    color="#000000"
                    fontWeight="300"
                    px="15px"
                    py="121px"
                    textAlign="center"
                  >
                    Drag & drop your files here or{" "}
                    <Link href="/" color="#1B75BC">
                      browse
                    </Link>{" "}
                    to upload.
                  </Box>
                </Flex>
                <Flex
                  // width="285px"
                  display={fileClinicalBoolean === false ? "none" : "flex"}
                  width="285px"
                  // height="272px"
                  height={ifSmallScreen ? "245px" : "272px"}
                  borderRadius="5px"
                  bg="#FFFFFF"
                  direction="column"
                  overflow="auto"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#C4C4C4",
                    },
                  }}
                >
                  <Flex pl="13px" pt="8px">
                    <Text
                      color="#000000"
                      fontSize={ifSmallScreen ? "13px" : "15.5px"}
                      fontWeight="500"
                      width="100%"
                    >
                      Uploaded form
                    </Text>
                  </Flex>
                  {allFiles?.map((file) => {
                    return allFiles?.length > 0 ? (
                      <Flex
                        mr="10px"
                        ml="10px"
                        mt="14px"
                        key={Math.random()}
                        bg="#1B75BC0A"
                      >
                        <HStack
                          // bg="#1B75BC0A"
                          h="58px"
                          w="270px"
                          borderRadius="6px"
                        >
                          <Flex
                            h="38px"
                            w="38px"
                            bg="#1B75BC"
                            fontSize="12px"
                            color="#FFFFFF"
                            alignItems="center"
                            justifyContent="center"
                            ml="6px"
                          >
                            {file.name.slice(-3) === "peg"
                              ? "jpeg"
                              : file.name.slice(-3) === "ocx"
                              ? "docx"
                              : file.name.slice(-3)}
                          </Flex>
                          <Flex direction="column" gap="2px" pl="5px">
                            <Text
                              fontSize={ifSmallScreen ? "10px" : "12.5px"}
                              fontWeight="400"
                              pb="4px"
                            >
                              {file?.name}
                            </Text>
                            {uploadProgress ? (
                              <Progress
                                colorScheme="blue"
                                height="3px"
                                width="135px"
                                value={uploadProgress}
                              />
                            ) : (
                              " "
                            )}
                          </Flex>
                          {/* <Box pb="6px" pl="3px" pr="3px">
                            <MdModeEdit size={15.5} />
                          </Box> */}
                        </HStack>
                        <Box
                          as="button"
                          pb="4px"
                          pl="40px"
                          pr="5px"
                          onClick={(e) => {
                            onDeselectFile(e, file?.name);
                          }}
                        >
                          <GrClose size={13.5} />
                        </Box>
                      </Flex>
                    ) : null;
                  })}
                </Flex>
              </HStack>
            </Flex>
            <Flex
              mt={ifSmallScreen ? "10px" : "32px"}
              ml={ifSmallScreen ? "1px" : "2px"}
              direction="column"
            >
              <Text
                fontSize={ifSmallScreen ? "13px" : "15.5px"}
                fontWeight="500"
                color="#000000"
                pb={ifSmallScreen ? "10px" : "15px"}
              >
                Description
              </Text>
              <Flex border="1px solid #1B75BC" borderRadius="3px">
                <Textarea
                  maxLength="450"
                  resize="none"
                  height={ifSmallScreen ? "80px" : "119px"}
                  width="530px"
                  fontSize="15.5px"
                  fontWeight="400"
                  borderRadius="3px"
                  id="clinical_history_details"
                  name="clinicalHistoryDetails"
                  value={textAreaDescription}
                  p="8px"
                  placeholder="Enter text here"
                  // onChange={(e) => handleCaseDetails(e)}
                  onChange={(e) => setTextArea(e.target.value)}
                >
                  There should been extensive tissue loss in the right cerebral
                  hemisphere due to an old unsult, presumably an ischemic event.
                </Textarea>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setIsModal1Open(false);
              }}
              colorScheme="#1B75BC"
              mr={ifSmallScreen ? "6" : "10"}
              color="#010202"
              // fontSize={ifSmallScreen ? "10px" : "14px"}
              fontWeight="400"
              border="1px solid #1B75BC"
              borderRadius="5px"
              // height={ifSmallScreen ? "25px" : "36px"}
              height={ifSmallScreen ? "25px" : "36px"}
              fontSize={ifSmallScreen ? "13px" : "14px"}
              width="81px"
            >
              Cancel
            </Button>
            <Button
              colorScheme="#1B75BC"
              bg="#1B75BC"
              color="#E6EBF3"
              fontSize={ifSmallScreen ? "13px" : "14px"}
              fontWeight="400"
              borderRadius="5px"
              height={ifSmallScreen ? "25px" : "36px"}
              width="81px"
              _hover={{ opacity: 1 }}
              onClick={handleUpdateClinicalHistory}
              isDisabled={click}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* modal end  1 */}
      {/* modal start 2 */}
      <Modal
        blockScrollOnMount={false}
        isOpen={isModal2Open}
        onClose={() => setIsModal2Open(false)}
        height={ifSmallScreen ? "500px" : "635px"}
        width={ifSmallScreen ? "500px" : "716px"}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            // fontSize="15.5px"
            fontSize={ifSmallScreen ? "13px" : "15.5px"}
            font-weight="500"
            // pl="27px"
            // pb="15px"
            // pt="19px"
            pl={ifSmallScreen ? "20px" : "27px"}
            pb={ifSmallScreen ? "10px" : "15px"}
            pt={ifSmallScreen ? "10px" : "19px"}
            bg="#F0F2FF"
            borderStyle="solid"
            borderWidth="0px 0px 2px 0px"
            borderColor="#DEDEDE"
          >
            Upload Radiology Image
          </ModalHeader>
          <ModalCloseButton
            // pb="15px"
            // pt="21px"
            // mr="10.5"
            pb={ifSmallScreen ? "20px" : "15px"}
            pt={ifSmallScreen ? "10px" : "21px"}
            mr={ifSmallScreen ? "10px" : "10.5"}
            _hover={{ opacity: 1 }}
          />
          <ModalBody bg="#FCFCFC">
            <Flex>
              <HStack
                mt={ifSmallScreen ? "5px" : "20px"}
                ml={ifSmallScreen ? "1px" : "2px"}
                justifyContent="space-between"
              >
                <Flex
                  width={fileRadiologyBoolean === false ? "529px" : "240px"}
                  height={ifSmallScreen ? "245px" : "272px"}
                  border="1px dashed #1B75BC"
                  borderRadius="5px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Input
                    id="radiology_images"
                    name="radiologyImages"
                    position="absolute"
                    opacity="0"
                    onInput={(e) => onSelectFile1(e)}
                    type="file"
                    accept="image/*"
                    // width="40%"
                    width={ifSmallScreen ? "200px" : "240px"}
                    height={ifSmallScreen ? "245px" : "272px"}
                    // height="50%"
                    multiple
                  />
                  <Box
                    fontSize="12px"
                    color="#000000"
                    fontWeight="300"
                    px="15px"
                    py="121px"
                    textAlign="center"
                  >
                    Drag & drop your files here or{" "}
                    <Link href="/" color="#1B75BC">
                      browse
                    </Link>{" "}
                    to upload.
                  </Box>
                </Flex>
                <Flex
                  // width="285px"
                  display={fileRadiologyBoolean === false ? "none" : "flex"}
                  width="285px"
                  // height="272px"
                  height={ifSmallScreen ? "245px" : "272px"}
                  borderRadius="5px"
                  bg="#FFFFFF"
                  direction="column"
                  overflow="auto"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#C4C4C4",
                    },
                  }}
                >
                  <Flex pl="13px" pt="8px">
                    <Text
                      color="#000000"
                      fontSize={ifSmallScreen ? "13px" : "15.5px"}
                      fontWeight="500"
                      width="100%"
                    >
                      Uploaded form
                    </Text>
                  </Flex>
                  {allFiles1?.map((file) => {
                    return allFiles1?.length > 0 ? (
                      <Flex
                        mr="10px"
                        ml="10px"
                        mt="14px"
                        key={Math.random()}
                        bg="#1B75BC0A"
                      >
                        <HStack
                          // bg="#1B75BC0A"
                          h="58px"
                          w="270px"
                          borderRadius="6px"
                        >
                          <Flex
                            h="38px"
                            w="38px"
                            bg="#1B75BC"
                            fontSize="12px"
                            color="#FFFFFF"
                            alignItems="center"
                            justifyContent="center"
                            ml="6px"
                          >
                            {file.name.slice(-3) === "peg"
                              ? "jpeg"
                              : file.name.slice(-3)}
                          </Flex>
                          <Flex direction="column" gap="2px" pl="5px">
                            <Text
                              fontSize={ifSmallScreen ? "10px" : "12.5px"}
                              fontWeight="400"
                              pb="4px"
                            >
                              {file?.name}
                            </Text>
                            {uploadProgress ? (
                              <Progress
                                colorScheme="blue"
                                height="3px"
                                width="135px"
                                value={uploadProgress}
                              />
                            ) : (
                              " "
                            )}
                          </Flex>
                          {/* <Box pb="6px" pl="3px" pr="3px">
                            <MdModeEdit size={15.5} />
                          </Box> */}
                        </HStack>
                        <Box
                          as="button"
                          pb="4px"
                          pl="40px"
                          pr="5px"
                          onClick={(e) => {
                            onDeselectFile1(e, file?.name);
                          }}
                        >
                          <GrClose size={13.5} />
                        </Box>
                      </Flex>
                    ) : null;
                  })}
                </Flex>
              </HStack>
            </Flex>
            <Flex
              mt={ifSmallScreen ? "10px" : "32px"}
              ml={ifSmallScreen ? "1px" : "2px"}
              direction="column"
            >
              <Text
                fontSize={ifSmallScreen ? "13px" : "15.5px"}
                fontWeight="500"
                color="#000000"
                pb={ifSmallScreen ? "10px" : "15px"}
              >
                Description
              </Text>
              <Flex border="1px solid #1B75BC" borderRadius="3px">
                <Textarea
                  maxLength="450"
                  resize="none"
                  height={ifSmallScreen ? "80px" : "119px"}
                  width="530px"
                  fontSize="15.5px"
                  fontWeight="400"
                  borderRadius="3px"
                  id="radiology_description"
                  name="radiologyInstructions"
                  value={textAreaDescription1}
                  p="8px"
                  placeholder="Enter text here"
                  // onChange={(e) => handleCaseDetails(e)}
                  onChange={(e) => setTextArea1(e.target.value)}
                >
                  There should been extensive tissue loss in the right cerebral
                  hemisphere due to an old unsult, presumably an ischemic event.
                </Textarea>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setIsModal2Open(false);
              }}
              colorScheme="#1B75BC"
              mr={ifSmallScreen ? "6" : "10"}
              color="#010202"
              // fontSize={ifSmallScreen ? "10px" : "14px"}
              fontWeight="400"
              border="1px solid #1B75BC"
              borderRadius="5px"
              // height={ifSmallScreen ? "25px" : "36px"}
              height={ifSmallScreen ? "25px" : "36px"}
              fontSize={ifSmallScreen ? "13px" : "14px"}
              width="81px"
            >
              Cancel
            </Button>
            <Button
              colorScheme="#1B75BC"
              bg="#1B75BC"
              color="#E6EBF3"
              // fontSize={ifSmallScreen ? "10px" : "14px"}
              fontWeight="400"
              borderRadius="5px"
              // height={ifSmallScreen ? "25px" : "36px"}
              height={ifSmallScreen ? "25px" : "36px"}
              fontSize={ifSmallScreen ? "13px" : "14px"}
              width="81px"
              _hover={{ opacity: 1 }}
              onClick={handleUpdateRadiologyHistory}
              isDisabled={click1}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* modal end  2 */}
      {/* modal start 3 */}
      <Modal
        // blockScrollOnMount={false}
        isOpen={isModal3Open}
        onClose={() => setIsModal3Open(false)}
      >
        <ModalOverlay />
        {/* <ModalContent maxH="650px" maxW="1100px" minH="650px" minW="1100px"> */}
        <ModalContent
          maxH={ifSmallScreen ? "480px" : "650px"}
          maxW={ifSmallScreen ? "1000px" : "1100px"}
          minH={ifSmallScreen ? "480px" : "650px"}
          minW={ifSmallScreen ? "1000px" : "1100px"}
        >
          <ModalHeader>
            <Flex justifyContent="flex-end" pr="32px">
              <Box
                as="button"
                height="33px"
                width="166px"
                border="1px solid #E8E8EB"
                color="red"
                fontSize="13px"
                borderRadius="5px"
                onClick={() => {
                  handelDeleteFile(allFiles2[0]?._id);
                }}
              >
                <Flex justifyContent="space-evenly">
                  Delete Document{" "}
                  <Box mt="3px">
                    <FiTrash2 />
                  </Box>
                </Flex>
              </Box>

              <ModalCloseButton
                _hover={{ opacity: 1 }}
                mt="9px"
                border="1px solid #E8E8EB"
              />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex>
              {allFiles2[0]?.docUrl.slice(-3) === "png" ||
              allFiles2[0]?.docUrl.slice(-3) === "jpg" ||
              allFiles2[0]?.docUrl.slice(-4) === "jpeg" ? (
                <Flex pb="17px" pl="20px" pr="20px" direction="column">
                  {/* <Flex width="630px" height="550px">
                    <Image
                      src={allFiles2[0]?.docUrl}
                      alt=""
                      css={{ cursor: "-webkit-grab" }}
                      maxH="350px"
                    />
                  </Flex> */}
                  <Box
                    width={ifSmallScreen ? "400px" : "630px"}
                    height={ifSmallScreen ? "300px" : "550px"}
                  >
                    <Image
                      src={allFiles2[0]?.docUrl}
                      alt="Image description"
                      width="100%"
                      height="100%"
                      objectFit="contain"
                      css={{ cursor: "-webkit-grab" }}
                    />
                  </Box>
                </Flex>
              ) : allFiles2[0]?.docUrl.slice(-3) === "pdf" ? (
                <Flex pb="17px" pl="20px" pr="20px">
                  <object
                    data={allFiles2[0]?.docUrl}
                    type="application/pdf"
                    // width="670"
                    // height="550"
                    // width="630px"
                    // height="550px"
                    width="630px"
                    height={ifSmallScreen ? "350px" : "550px"}
                    style={{
                      overflow: "hidden",
                    }}
                  >
                    <p>
                      Alternative text - include a link{" "}
                      <a href={allFiles2[0]?.docUrl}>to the PDF!</a>
                    </p>
                  </object>
                </Flex>
              ) : (
                <Flex direction="column" pt="8px" pb="17px" pl="20px" pr="20px">
                  <iframe
                    title={allFiles2[0]?.docUrl}
                    // src={`https://docsUrl.google.com/gview?url=${object.docurl}&embedded=true`}
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${allFiles2[0]?.docUrl}&embedded=true`}
                    frameBorder="0"
                    width="400px"
                    height="350px"
                    style={{
                      overflow: "hidden",
                    }}
                  />
                </Flex>
              )}
              <Flex direction="column" gap="20px">
                <Box w="80%" wordBreak="break-word">
                  <Text
                    fontSize={ifSmallScreen ? "15px" : "24px"}
                    fontWeight="600"
                    color="#000000"
                  >
                    {allFiles2[0]?.docName}
                  </Text>
                </Box>
                <Flex gap="20px">
                  <Flex direction="column">
                    <Text
                      fontSize={ifSmallScreen ? "13px" : "15px"}
                      fontWeight="500"
                      color="#6A6A6A"
                      pb="3px"
                    >
                      Uploaded On
                    </Text>
                    <Text
                      fontSize={ifSmallScreen ? "12px" : "16px"}
                      fontWeight="500"
                      color="#000000"
                    >
                      {timeStamp}
                    </Text>
                  </Flex>
                  <Flex direction="column" pl="35px">
                    <Text
                      fontSize={ifSmallScreen ? "13px" : "16px"}
                      fontWeight="500"
                      color="#6A6A6A"
                      pb="3px"
                    >
                      Uploaded By
                    </Text>
                    <Text
                      fontSize={ifSmallScreen ? "12px" : "16px"}
                      fontWeight="500"
                      color="#000000"
                    >
                      {allFiles2[0]?.uploadedBy}
                    </Text>
                  </Flex>
                </Flex>
                <Flex justifyContent="flex-start">
                  <Flex direction="column">
                    <Text
                      fontSize={ifSmallScreen ? "13px" : "15px"}
                      fontWeight="500"
                      color="#6A6A6A"
                      pb="3px"
                    >
                      Size
                    </Text>
                    <Text
                      fontSize={ifSmallScreen ? "12px" : "16px"}
                      fontWeight="500"
                      color="#000000"
                      pb="3px"
                    >
                      {(Number(allFiles2[0]?.docSize) / 1024).toFixed(2)}
                      {" MB"}
                    </Text>
                  </Flex>
                  <Flex
                    direction="column"
                    pl={
                      allFiles2[0]?.docType?.toUpperCase() !== "PDF"
                        ? "112px"
                        : "100px"
                    }
                  >
                    <Text
                      fontSize={ifSmallScreen ? "13px" : "15px"}
                      fontWeight="500"
                      color="#6A6A6A"
                      pb="3px"
                    >
                      Type
                    </Text>
                    <Text
                      fontSize={ifSmallScreen ? "12px" : "16px"}
                      fontWeight="500"
                      color="#000000"
                    >
                      {allFiles2[0]?.docType?.toUpperCase()}
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap="20px">
                  <Flex direction="column">
                    <Flex
                      fontSize={ifSmallScreen ? "13px" : "15px"}
                      fontWeight="500"
                      color="#6A6A6A"
                      pb="3px"
                    >
                      <Box mr="3px">
                        <Text>Description </Text>
                      </Box>
                      <Flex mt="3px" ml="5px">
                        <SlPencil size="13px" />
                      </Flex>
                    </Flex>
                    <Box w="90%" wordBreak="break-word">
                      <Text
                        fontSize={ifSmallScreen ? "11px" : "16px"}
                        fontWeight="500"
                        color="#000000"
                      >
                        {allFiles2[0]?.docDescription}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* modal end  3 */}
      {/* modal start 4 */}
      <Modal
        // blockScrollOnMount={false}
        isOpen={isModal4Open}
        onClose={() => setIsModal4Open(false)}
      >
        <ModalOverlay />
        {/* <ModalContent maxH="650px" maxW="1100px" minH="650px" minW="1100px"> */}
        <ModalContent
          maxH={ifSmallScreen ? "480px" : "650px"}
          maxW={ifSmallScreen ? "1000px" : "1100px"}
          minH={ifSmallScreen ? "480px" : "650px"}
          minW={ifSmallScreen ? "1000px" : "1100px"}
        >
          <ModalHeader>
            <Flex justifyContent="flex-end" pr="32px">
              <Button
                as="button"
                height="33px"
                width="166px"
                border="1px solid #E8E8EB"
                color="red"
                fontSize="13px"
                borderRadius="5px"
                onClick={() => {
                  handelDeleteFile1(allFiles3[0]?._id);
                }}
                disabled={isActionValid("radiologyImageDelete", permissions)}
              >
                <Flex>
                  Delete Document{" "}
                  <Box ml="10px">
                    <FiTrash2 />
                  </Box>
                </Flex>
              </Button>

              <ModalCloseButton
                _hover={{ opacity: 1 }}
                mt="9px"
                border="1px solid #E8E8EB"
              />
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex>
              {allFiles3[0]?.docUrl.slice(-3) === "png" ||
              allFiles3[0]?.docUrl.slice(-3) === "jpg" ||
              allFiles3[0]?.docUrl.slice(-4) === "jpeg" ? (
                <Flex pb="17px" pl="20px" pr="20px" direction="column">
                  <Box
                    width={ifSmallScreen ? "350px" : "630px"}
                    height={ifSmallScreen ? "300px" : "550px"}
                  >
                    {/* <Image
                      src={allFiles3[0]?.docUrl}
                      alt=""
                      css={{ cursor: "-webkit-grab" }}
                    /> */}
                    <Image
                      src={allFiles3[0]?.docUrl}
                      alt="Image description"
                      width="100%"
                      height="100%"
                      objectFit="contain"
                      css={{ cursor: "-webkit-grab" }}
                    />
                  </Box>
                </Flex>
              ) : allFiles2[0]?.docUrl.slice(-3) === "pdf" ? (
                <Flex pb="17px" pl="20px" pr="20px">
                  <object
                    data={allFiles3[0]?.docUrl}
                    type="application/pdf"
                    width="630px"
                    height="550px"
                    style={{
                      overflow: "hidden",
                      cursor: "-webkit-grab",
                    }}
                  >
                    <p>
                      Alternative text - include a link{" "}
                      <a href={allFiles3[0]?.docUrl}>to the PDF!</a>
                    </p>
                  </object>
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  pt="17px"
                  pb="17px"
                  pl="20px"
                  pr="20px"
                >
                  <iframe
                    title={allFiles3[0]?.docUrl}
                    // src={`https://docsUrl.google.com/gview?url=${object.docurl}&embedded=true`}
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${allFiles2[0]?.docUrl}&embedded=true`}
                    frameBorder="0"
                    width="630px"
                    height="550px"
                    style={{
                      overflow: "hidden",
                    }}
                  />
                </Flex>
              )}
              <Flex direction="column" pl="20px" gap="20px">
                <Box w="80%" wordBreak="break-word">
                  <Text
                    fontSize={ifSmallScreen ? "16px" : "24px"}
                    fontWeight="600"
                    color="#000000"
                  >
                    {allFiles3[0]?.docName}
                  </Text>
                </Box>
                <Flex gap="25px">
                  <Flex direction="column">
                    <Text
                      fontSize={ifSmallScreen ? "13px" : "15px"}
                      fontWeight="500"
                      color="#6A6A6A"
                      pb="3px"
                    >
                      Uploaded On
                    </Text>
                    <Text
                      fontSize={ifSmallScreen ? "12px" : "16px"}
                      fontWeight="500"
                      color="#000000"
                    >
                      {timeStamp1}
                    </Text>
                  </Flex>
                  <Flex direction="column" pl="35px">
                    <Text
                      fontSize={ifSmallScreen ? "13px" : "15px"}
                      fontWeight="500"
                      color="#6A6A6A"
                      pb="3px"
                    >
                      Uploaded By
                    </Text>
                    <Text
                      fontSize={ifSmallScreen ? "12px" : "16px"}
                      fontWeight="500"
                      color="#000000"
                    >
                      {allFiles3[0]?.uploadedBy}
                    </Text>
                  </Flex>
                </Flex>
                <Flex>
                  <Flex direction="column">
                    <Text
                      fontSize={ifSmallScreen ? "13px" : "15px"}
                      fontWeight="500"
                      color="#6A6A6A"
                      pb="3px"
                    >
                      Size
                    </Text>
                    <Text
                      fontSize={ifSmallScreen ? "12px" : "16px"}
                      fontWeight="500"
                      color="#000000"
                      pb="3px"
                    >
                      {(Number(allFiles3[0]?.docSize) / 1024).toFixed(2)}
                      {" MB"}
                    </Text>
                  </Flex>
                  <Flex direction="column" pl="112px">
                    <Text
                      fontSize={ifSmallScreen ? "13px" : "15px"}
                      fontWeight="500"
                      color="#6A6A6A"
                      pb="3px"
                    >
                      Type
                    </Text>
                    <Text
                      fontSize={ifSmallScreen ? "12px" : "16px"}
                      fontWeight="500"
                      color="#000000"
                    >
                      {allFiles3[0]?.docType?.toUpperCase()}
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap="20px">
                  <Flex direction="column">
                    <Flex
                      fontSize={ifSmallScreen ? "13px" : "15px"}
                      fontWeight="500"
                      color="#6A6A6A"
                      pb="3px"
                    >
                      <Box mr="3px">
                        <Text>Description </Text>
                      </Box>
                      <Flex mt="3px" ml="5px">
                        <SlPencil size="13px" />
                      </Flex>
                    </Flex>
                    <Box w="100%" wordBreak="break-word">
                      <Text
                        fontSize={ifSmallScreen ? "12px" : "16px"}
                        fontWeight="500"
                        color="#000000"
                      >
                        {allFiles3[0]?.docDescription}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* modal end  4 */}
    </Box>
  );
};

export default ClinicalHistory;
